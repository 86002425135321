"use client";

import React, { useState } from "react";
import { Image } from "@heroui/image";
import { Link } from "@heroui/link";

export function MusicCard({ title, subtitle, image, link }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link
      href={link}
      target="_blank"
      className="group relative flex w-full gap-4 rounded-2xl p-2 transition-all duration-300 hover:bg-foreground-100"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="relative flex-shrink-0">
        <Image
          className={`aspect-square size-16 max-w-16 overflow-hidden rounded-lg object-cover transition-all duration-300`}
          src={`${image}?auto=format`}
          alt={`Album cover for ${title}`}
        />
      </div>

      <div className="flex w-full flex-col items-start gap-1">
        <header className="text-lg font-bold leading-tight text-foreground-700">
          {title}
        </header>
        <span className="text-sm text-foreground-500">{subtitle}</span>
      </div>
    </Link>
  );
}
