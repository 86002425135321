"use client";

import { motion, useAnimation } from "motion/react";
import { useEffect } from "react";

export function MusicBars() {
  const bars = [1, 2, 3, 4];
  const controls = useAnimation();

  useEffect(() => {
    const animateBars = async () => {
      await controls.start((i) => ({
        height: [8, Math.random() * 6 + 8, 8],
        transition: {
          duration: Math.random() * 1.5 + 1.5,
          times: [0, 0.5, 1],
          repeat: Infinity,
          repeatType: "reverse",
          delay: i * 0.2,
        },
      }));
    };

    animateBars();
  }, [controls]);

  return (
    <div className="flex items-center justify-center space-x-0.5">
      {bars.map((bar) => (
        <motion.div
          key={bar}
          custom={bar - 1}
          animate={controls}
          style={{
            height: "0.5rem",
            width: "0.25rem",
            borderRadius: "0.25rem",
            background: "linear-gradient(to top, #dd6b20, #fed7aa)",
            originY: "50%",
          }}
        />
      ))}
    </div>
  );
}
