"use client";

import * as React from "react";
import { CalendarCheck, ChevronDown } from "lucide-react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@heroui/dropdown";
import { Button } from "@heroui/button";

export function BookMeeting() {
  return (
    <Dropdown>
      <DropdownTrigger>
        <Button
          className="w-full sm:w-max"
          variant="flat"
          name="Book a meeting"
          endContent={<ChevronDown size={16} />}
        >
          Schedule a chat
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        aria-label="Book Time"
        onAction={(key) => {
          if (key === "book-15") {
            window.open("https://cal.com/karlkoch/15min", "_blank");
          } else if (key === "book-30") {
            window.open("https://cal.com/karlkoch/30min", "_blank");
          }
        }}
      >
        <DropdownItem key="book-15" startContent={<CalendarCheck size={16} />}>
          Book 15m meeting
        </DropdownItem>
        <DropdownItem key="book-30" startContent={<CalendarCheck size={16} />}>
          Book 30m meeting
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
