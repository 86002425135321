"use client";

import { Accordion, AccordionItem } from "@heroui/accordion";
import * as Type from "@/lib/types";

const PrincipleAccordion = ({
  principles,
}: {
  principles: Type.Principle[];
}) => {
  const itemClasses = {
    base: "w-full",
    title: "text-lg font-medium text-foreground-700",
    trigger: "w-full",
    indicator: "text-medium",
    content: "text-foreground-500",
  };

  return (
    <Accordion
      variant="light"
      isCompact
      showDivider={false}
      className="grid w-full grid-cols-1 gap-4 px-0 sm:grid-cols-2"
      fullWidth
      itemClasses={itemClasses}
    >
      {principles.map((principle) => {
        return (
          <AccordionItem
            key={principle.id}
            aria-label={principle.title}
            title={principle.title}
          >
            {principle.metadata.content}
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default PrincipleAccordion;
