"use client";

import Link from "next/link";
import React, { useEffect, useState } from "react";
import { MusicBars } from "./music-bars";
import { Image, Skeleton } from "@heroui/react";

interface NowPlayingProps {
  className?: string;
}

interface Track {
  albumName: string;
  artistName: string;
  artworkUrl: string;
  songName: string;
  isPlaying: boolean;
  songUrl: string;
}

export function NowPlaying({ className = "" }: NowPlayingProps) {
  const [currentTrack, setCurrentTrack] = useState<Track | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  // Fetch now playing data only once on component mount
  useEffect(() => {
    async function fetchNowPlaying() {
      try {
        const response = await fetch("/api/now-playing", {
          cache: "no-store",
        });

        if (response.status === 401 || response.status === 403) {
          // Token issues - we'll just hide the component
          console.log("Apple Music token issues, hiding NowPlaying component");
          return;
        }

        if (!response.ok) {
          // Other errors
          const message = await response.text();
          console.error("Error fetching now playing:", message);
          return;
        }

        const data = await response.json();

        if (!data.isPlaying || !data.songUrl) {
          // Not playing or incomplete data
          return;
        }

        setCurrentTrack({
          albumName: data.albumName,
          artistName: data.artistName,
          artworkUrl: data.artworkUrl,
          songName: data.songName,
          isPlaying: data.isPlaying,
          songUrl: data.songUrl,
        });

        // Mark as loaded after a small delay for smoother transition
        setTimeout(() => {
          setIsLoaded(true);
        }, 100);
      } catch (err) {
        console.error("Error in NowPlaying component:", err);
      }
    }

    // Fetch data once on component mount
    fetchNowPlaying();
  }, []); // Empty dependency array ensures this runs only once

  // Don't render anything if there's an error or no data after loading
  if (!currentTrack && isLoaded) {
    return null;
  }

  // Common styling for container
  const containerStyles =
    "rounded-xl border border-foreground-100 bg-background overflow-hidden w-full max-w-[320px]";

  // If data is loaded, show the actual content
  if (isLoaded && currentTrack) {
    return (
      <Link
        href={currentTrack.songUrl}
        target="_blank"
        rel="noopener noreferrer"
        className={`block ${containerStyles} ${className}`}
      >
        <div className="relative p-4">
          {/* Background album art with blur */}
          <div
            className="absolute inset-0 z-0 opacity-40"
            style={{
              backgroundImage: `url(${currentTrack.artworkUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              filter: "blur(30px)",
              transform: "scale(1.1)", // Prevents blur edges from showing
            }}
          />

          {/* Overlay gradient for better text contrast */}
          <div className="absolute inset-0 z-10 bg-gradient-to-r from-black/70 to-black/40 dark:from-black/80 dark:to-black/60" />

          <div className="relative z-20 flex items-center gap-4">
            <div className="relative flex-shrink-0">
              <Image
                src={currentTrack.artworkUrl}
                alt={`${currentTrack.songName} album artwork`}
                className="size-16 rounded-md object-cover shadow-sm"
                width={64}
                height={64}
              />
              <div className="absolute bottom-1 right-1">
                <MusicBars />
              </div>
            </div>

            <div className="min-w-0 flex-1">
              <p className="truncate text-sm font-medium text-white">
                {currentTrack.songName}
              </p>
              <p className="truncate text-xs text-gray-200">
                {currentTrack.artistName} • {currentTrack.albumName}
              </p>
              <div className="mt-1 flex items-center gap-1">
                <span className="text-xs text-gray-300">Now playing</span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  }

  // Otherwise show the skeleton with exactly the same dimensions
  return (
    <div className={`${containerStyles} ${className}`}>
      <div className="p-4">
        <div className="flex items-center gap-4">
          <Skeleton className="h-16 w-16 flex-shrink-0 rounded-md" />
          <div className="flex min-w-0 flex-1 flex-col gap-2">
            <Skeleton className="h-3 w-32 rounded" />
            <Skeleton className="h-2 w-48 rounded" />
            <Skeleton className="h-2 w-24 rounded" />
          </div>
        </div>
      </div>
    </div>
  );
}
