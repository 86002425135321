import React from "react";
import { PageHeaderProps } from "@/lib/types";

export function HomeHeader({ children }: PageHeaderProps) {
  return (
    <h1 className="text-balance pt-4 font-sans text-2xl font-bold tracking-tight sm:text-4xl sm:font-bold sm:leading-none">
      <div className="text-foreground-400">Karl Emil James Koch.</div>
      {children}
    </h1>
  );
}
