"use client";

import React from "react";
import { ContactMe } from "./contact-me";
import { HomeHeader } from "./home-header";
import { DDG } from "@/components/icons/companies";
import { BookMeeting } from "./book-meeting";

export function HomeHero({ home }) {
  return (
    <div className="w-full">
      <div className="flex w-full items-start justify-between">
        <div className="relative z-10 flex w-full flex-col justify-start sm:max-w-4xl">
          <HomeHeader>{home.title}</HomeHeader>
          <p className="mt-6 max-w-3xl text-xl font-normal !leading-snug tracking-tight text-foreground-600 lg:text-2xl">
            Design Engineer at{" "}
            <a
              href="https://www.duckduckgo.com"
              target="_blank"
              className="subtitleAnchor ml-1 mr-2 whitespace-nowrap"
            >
              <DDG className="mr-1.5 inline-block size-4 lg:size-8" />
              DuckDuckGo
            </a>
          </p>
        </div>
      </div>
      <div className="relative z-10 mx-auto mt-8 flex w-full justify-center space-x-4 sm:justify-start">
        <ContactMe />
        <BookMeeting />
      </div>
    </div>
  );
}
