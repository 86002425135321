"use client";

import { useEffect, useState } from "react";
import { NowPlaying } from "./now-playing";
import { AllCapsHeader } from "@/components/ui/all-caps-header";

export function NowPlayingSection() {
  const [isAvailable, setIsAvailable] = useState<boolean | null>(true); // Start with true to show skeleton immediately

  useEffect(() => {
    async function checkAvailability() {
      try {
        const response = await fetch("/api/now-playing", {
          cache: "no-store",
        });

        if (response.status === 401 || response.status === 403) {
          // Token issues - only hide if authentication fails
          console.log("Apple Music token issues, hiding NowPlaying section");
          setIsAvailable(false);
          return;
        }

        // Any other status is acceptable - we'll let NowPlaying handle
        // the display logic for when music is playing or not
        setIsAvailable(true);
      } catch (err) {
        console.error("Error checking Apple Music availability:", err);
        // Don't hide on network errors, let the component retry
        setIsAvailable(true);
      }
    }

    // Only perform availability check once
    checkAvailability();
  }, []); // Empty dependency array to run once on mount

  // Only hide the section if token validation explicitly failed
  if (isAvailable === false) {
    return null;
  }

  return (
    <div className="w-full pt-8">
      <AllCapsHeader size="text-xs" pb="pb-1">
        Now Playing
      </AllCapsHeader>

      <div className="mt-4">
        <NowPlaying />
      </div>
    </div>
  );
}
