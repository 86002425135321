import React from "react";
import classNames from "classnames";
import { AllCapsHeaderProps } from "@/lib/types";

export function AllCapsHeader({
  children,
  pt,
  pb,
  pl,
  pr,
  size,
  justify = "justify-start",
}: AllCapsHeaderProps) {
  return (
    <span
      className={classNames(
        `flex w-full items-center text-balance ${justify} font-mono ${pt} ${pb} pl-${pl} pr-${pr} ${size} uppercase tracking-wider text-foreground-500`,
      )}
    >
      {children}
    </span>
  );
}
